<template>
  <div>
    <slot
      :lastSearchSettings="lastSearchSettings"
      :results="results"
      :resultsAvailable="resultsAvailable"
      :loading="loading"
      :error="error"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const {
  mapState,
  mapGetters,
} = createNamespacedHelpers('searchSettings')

export default {
  computed: {
    ...mapState(['error', 'lastSearchSettings']),
    ...mapGetters(['results', 'resultsAvailable', 'loading']),
  },
}
</script>
