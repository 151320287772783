import { createApp, h } from 'vue'
import { Quasar } from 'quasar'

import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'
import store from './plugins/vuex'
import './plugins/quasar'

import { ENV, PRODUCTION } from './constants/misc'

if (ENV !== PRODUCTION) {
  document.title = ENV.toUpperCase()
}

const app = createApp({
  render: () => h(App)
})


app.use(store)
app.use(router)
app.use(Quasar)
app.use(i18n)

app.mount('#app')
