<template>
  <InputDate
    :label="$t('setting.endDate')"
    :value="endDate"
    @input="setEndDate"
  />
</template>

<script>
import InputDate from './InputDate'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputDate
  },
  computed: {
    ...mapGetters(['endDate'])
  },
  methods: {
    ...mapMutations(['setEndDate'])
  }
}
</script>

<style scoped lang="scss"></style>
