<template>
  <router-link
    @click="$emit('click')"
    :to="{ name: tool, query: this.$route.query }"
    tag="div"
    class="tool-button"
  >
    <div class="bar" :class="{ selected: isCurrent }"></div>
    <div class="content">
      <div>
        <div class="name">
          {{ $t(`nav.${tool}`) }}
        </div>
        <div class="description">
          {{ $t(`toolDescription.${tool}`) }}
        </div>
      </div>
      <IconTool :tool="tool" :makePurple='isCurrent' :size='40' />
    </div>
  </router-link>
</template>

<script>
import IconTool from './IconTool'

export default {
  components: {
    IconTool
  },
  props: {
    tool: String
  },
  computed: {
    isCurrent () {
      return this.tool === this.$route.name
    }
  }
}
</script>

<style scoped lang="scss">
.tool-button {
  position: relative;
  cursor: pointer;
  user-select: none;
  color: var(--color-black);
  border-radius: var(--border-radius-1);
  background-color: var(--color-white);
  text-decoration: none;
  display: grid;
  grid-auto-flow: column;
}

.bar {
  height: 100%;
  width: var(--spacing-1);
  background-color: var(--color-blue);
  border-radius: var(--border-radius-1) 0 0 var(--border-radius-1);
}

.bar.selected {
  background-color: var(--color-purple);
}

.content {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-4);
  align-items: center;
  margin: var(--spacing-4);
  margin-left: var(--spacing-3);
  font-family: var(--primary-font);
}

.name {
  font-size: var(--fs-3);
  font-weight: bold;
  font-family: var(--primary-font);
}
</style>
