<template>
  <div class="q-pa-md q-gutter-sm">
    <div class="consent"
    ><h4>{{ $t("consent.consentReminder") }}</h4>
      <q-btn
        label="View Consent"
        text-color="white"
        color="black"
        @click="warning = true"
      />
    </div>
    <q-dialog v-model="warning" persistent>
      <q-card>
        <q-card-section class="consent">
          <h4>{{ $t("consent.contentWarning") }}</h4>
          <q-space />

          <div class=buttons>
            <q-btn
              type="submit"
              label="OK"
              color="black"
              text-color="white"
              @click="acceptConsent"
              v-close-popup
            />
            <q-btn
              icon="close"
              dense
              flat
              v-close-popup
            />
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>

export default {
  data () {
    return {
      warning: true,
    }
  },
  methods: {
    acceptConsent () {
      this.$store.dispatch('searchSettings/acceptConsent')
    }
  }
}
</script>


<style scoped lang="scss">
.consent {
  font-family: var(--primary-font);
}
.buttons {
  display: grid;
  grid-template-columns: auto auto;
}
</style>
