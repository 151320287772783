<template>
  <InputContainer>
    <div class="input-card picker-button" @click="modal = true" v-ripple>
      <IconTool class='tool-icon' :tool='tool' :makePurple='true' :size='40' />
      <div>
        <div class="tool-name">
          {{ $t(`nav.${tool}`) }}
        </div>
        <div class="fat-text">
          {{ $t("toolPicker.changeTools") }}
        </div>
      </div>
      <BaseIcon
        name='expand_more'
        class='arrow-icon'
        :style='{ color }'
        :class='{ rotate }'
      />
    </div>
    <ModalToolPicker v-model:value="modal" />
  </InputContainer>
</template>

<script>
import InputContainer from '../InputContainer'
import IconTool from '@/components/input/tool-picker/IconTool'
import ModalToolPicker from '@/components/input/tool-picker/ModalToolPicker'
import BaseIcon from '@/components/BaseIcon'

export default {
  components: {
    InputContainer,
    IconTool,
    ModalToolPicker,
    BaseIcon,
  },
  props: {
    rotate: Boolean,
    color: {
      default: 'var(--color-light-text)'
    }
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    tool () {
      return this.$route.name
    }
  }
}
</script>

<style scoped lang="scss">

.picker-button {
  position: relative;
  cursor: pointer;
  user-select: none;
  color: var(--color-black);
  border-radius: var(--border-radius-1);
  padding: var(--spacing-2);
  text-align: start;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.tool-icon {
  margin-right: var(--spacing-3);
}

.tool-name {
  font-family: inherit; /* This will make the text use the default CSS text */
  font-size: var(--fs-3);
  font-weight: bold;
}

.fat-text {
  text-transform: uppercase;
  font-weight: 800;
  font-size: var(--fs-1);
  letter-spacing: 1px;
  color: var(--color-purple);
}
</style>
