module.exports = {
  en: {
    ...require('./en/base.js'),
    about: require('./en/about.js')
  },
  es: {
    ...require('./es/base.js'),
    about: require('./es/about.js')
  }
}
