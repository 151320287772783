<template>
  <q-tooltip
    anchor="bottom middle"
    self="top middle"
    class="tooltip"
    :class="{ error: isError }"
    :model-value="alwaysShow ? true : null"
    :no-parent-event="alwaysShow"
  >
    <div class="tooltip-content">
      <slot/>
    </div>
  </q-tooltip>
</template>

<script>
export default {
  props: {
    alwaysShow: Boolean,
    isError: Boolean,
  }
}
</script>

<style lang="scss">
.tooltip {
  background: var(--color-dark-grey);
}

.tooltip.error {
  background: var(--color-error);
}
</style>

<style scoped lang="scss">
.tooltip-content {
  font-family: var(--primary-font);
  font-size: var(--fs-1);
  color: var(--color-off-white);
}
</style>