<template>
  <div>
    <div class="label">
      {{ label }}
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String
  }
}
</script>

<style scoped lang="scss">
.label {
  text-align: left;
  font-size: var(--fs-3);
  font-weight: 600;
  margin-bottom: var(--spacing-2);
  font: var(--primary-font);
}
</style>
