<template>
    <div class="wrapper">
      <div class="label">
        Only extract URL host?
      </div>
      <div
        @click="setHostRegex(!hostRegex)"
        class="toggle"
        :class="{ on: hostRegex }"
      >
        <div class="knob"></div>
      </div>
    </div>
  </template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  computed: {
    ...mapGetters(['hostRegex'])
  },
  methods: {
    ...mapMutations(['setHostRegex'])
  }
}
</script>

<style scoped lang="scss">
.wrapper {
display: flex;
}

.label {
text-align: left;
font-size: var(--fs-3);
font-weight: 600;
flex: 1;
}

.toggle {
background: var(--color-light-text);
transition: background 0.3s;
width: 3rem;
height: 1.5rem;
border-radius: 5rem;
cursor: pointer;
user-select: none;
display: flex;
align-items: center;
}

.toggle.on {
background: var(--color-primary);
}

.knob {
background: var(--color-white);
width: 1rem;
height: 1rem;
border-radius: 5rem;
margin-left: 0.25rem;
transition: margin-left 0.3s;
}

.on .knob {
margin-left: calc(3rem - 1.25rem);
}
</style>
