<template>
  <Bar :data="chartData" :options="options"/>
</template>

<script>
import { downloadBase64 } from '@/helpers/graphs'
import 'chart.js/auto'
import { Bar } from 'vue-chartjs'

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    }
  },
  methods: {
    downloadImage () {
      downloadBase64(this.base64Image)
    }
  },
}
</script>
<style></style>
