<template>
  <InputDate
    :label="$t('setting.startDate')"
    :value="startDate"
    @input="setStartDate"
  />
</template>

<script>
import InputDate from './InputDate'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputDate
  },
  computed: {
    ...mapGetters(['startDate'])
  },
  methods: {
    ...mapMutations(['setStartDate'])
  }
}
</script>

<style scoped lang="scss"></style>
