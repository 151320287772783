<template>
  <div>
    <LineChart ref="chart" :chartData="chartData" :options="chartOptions" :plugins="chartPlugins" />
  </div>
</template>

<script>
import 'chartjs-adapter-moment'
import colorLib from '@kurkle/color'
import LineChart from './LineChart'
import { chartPlugins } from '@/helpers/graphs'

function color (name) {
  const style = getComputedStyle(document.body)
  return style.getPropertyValue(name)
}

function transparentize (value, opacity) {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity
  return colorLib(value).alpha(alpha).rgbString()
}

const chartLook = (searchSettings) => ({
  backgroundColor: transparentize(color('--color-purple')),
  borderColor: color('--color-purple'),
  borderWidth: 1,
  fill: true,
  pointStyle: 'circle',
  pointRadius: searchSettings.interval === 'hour' ? 0 : 1.5,
  pointHoverRadius: 10,
})

export default {
  components: {
    LineChart
  },
  props: {
    resultsData: Object,
    searchSettings: Object,
    website: String,
    timelineType: String,
    subTitle: String
  },
  computed: {
    chartSubtitle () {
      function trunc (term) {
        if (!term) return ''
        return term.length > 15 ? term.slice(0, 15) + '…' : term
      }
      return this.subTitle + trunc(this.searchSettings.searchTerm) + ' [' + this.searchSettings.startDate + ' - ' + this.searchSettings.endDate + ']'
    },
    chartTitle () {
      return this.timelineType + this.website
    },
    chartPlugins () {
      return chartPlugins()
    },
    chartData () {
      return {
        datasets:
          this.resultsData.before !== undefined
            ? [
                {
                  ...chartLook(this.searchSettings),
                  label: this.$t('data.beforeChangepoint', {
                    site: this.resultsData.label
                  }),
                  data: this.resultsData.before.map((d) => ({
                    x: d.date,
                    y: d.usage
                  }))
                },
                {
                  ...chartLook(this.searchSettings),
                  backgroundColor: transparentize(color('--color-dark-blue')),
                  borderColor: color('--color-dark-blue'),
                  label: this.$t('data.afterChangepoint', {
                    site: this.resultsData.label
                  }),
                  data: this.resultsData.after.map((d) => ({
                    x: d.date,
                    y: d.usage
                  }))
                }
              ]
            : [
                {
                  ...chartLook(this.searchSettings),
                  label: this.resultsData.label,
                  data: this.resultsData.data.map((d) => ({
                    x: d.date,
                    y: d.usage
                  }))
                }
              ]
      }
    },
    chartOptions () {
      // chart.js is bad and doesn't let us translate stuff in a reasonable way so we just have everything in the default english instead
      // we could maybe switch to one of the other adapters (that isn't moment.js) to get some localization options
      // https://www.chartjs.org/docs/latest/axes/cartesian/time.html

      return {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.4,
        onResize: this.setHeight,
        layout: {
          padding: 10
        },
        plugins: {
          legend: {
            display: false
          },
          subtitle: {
            display: true,
            color: color('--color-light-text'),
            text: this.chartSubtitle,
            align: 'start',
            padding: {
              top: 5,
              bottom: 25,
            },
            font: {
              size: 16,
              weight: 500,
              family: 'Lexend, sans-serif'
            }
          },
          title: {
            display: true,
            color: color('--color-dark-text'),
            text: this.chartTitle,
            align: 'start',
            padding: {
              top: 0,
              bottom: 0,
            },
            font: {
              size: 24,
              weight: 'bold',
              family: 'Lexend, sans-serif'
            }
          },
          customCanvasBackgroundColor: {
            color: color('--color-white')
          }
        },
        scales: {
          x: {
            ticks: {
              source: 'data',
              font: {
                family: 'Lexend, sans-serif'
              }
            },
            type: 'time',
            time: {
              displayFormats: {
                quarter: 'MM-YY',
                day: 'MM-DD-YY',
                hour: 'MM-DD-YY HH:mm',
                month: 'MM-YY',
                week: 'MM-DD-YY'
              },
              unit: this.getUnit()
            },
            grid: {
              color: color('--color-dividers')
            }
          },
          y: {
            ticks: {
              font: {
                family: 'Lexend, sans-serif'
              }
            },
            beginAtZero: true
          }
        },
        animation: {
          onComplete: (ctx) => {
            this.$refs.chart.base64Image = ctx.chart.toBase64Image()
          }
        }
      }
    }
  },
  methods: {
    getUnit () {
      return this.searchSettings.interval
    },
    downloadImage () {
      this.$refs.chart.downloadImage()
    },
    setHeight () {
      this.$refs.chart.$el.style.height = '400px'
    }
  },
}
</script>

<style scoped lang="scss"></style>
