<template>
  <div>
    <div v-if="resultsAvailable">
      <div>
        <BaseCard v-for="(website, i) in resultsData" :key="website.label">
          <template #content>
            <BaseBarChart ref="chart" :resultsData="website.data" :searchSettings="searchSettings" :website="website.label" :barType="$t('chart.activityOn')" :subTitle="$t('chart.termAggBy')"/>
          </template>
          <template #footer>
            <FatButton @click="$refs.chart[i].downloadImage()">
              {{ $t("chart.downloadPNG") }}
            </FatButton>
          </template>
        </BaseCard>
      </div>

      <div>
        <TableCount
          v-for="website in resultsData"
          :key="website.label"
          :card-title="$t('table.viewFor') + website.label"
          :titles="[aggByLocalize(website), $t('table.count')]"
          :resultsData="website.data"
        />
      </div>
    </div>
    <BodyLoading :loading="loading" />
    <BodyError :error="error" />
  </div>
</template>

<script>
import BaseCard from '@/components/BaseCard'
import BaseBarChart from '@/components/charts/BaseBarChart'
import FatButton from '@/components/input/FatButton'
import TableCount from '@/components/tables/TableCount'
import BodyLoading from './BodyLoading'
import BodyError from './BodyError'
const { FOURCHAN, EIGHTKUN } = require('@/constants/sites')

export default {
  components: {
    BaseCard,
    BaseBarChart,
    FatButton,
    TableCount,
    BodyLoading,
    BodyError
  },
  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  computed: {
    /**
     * Takes the raw result from the fetch and turns it into a nice format
     * that we can use
     */
    searchSettings () {
      return this.lastSearchSettings
    },
    resultsData () {
      if (!this.results.length) return []

      return this.results.map(websiteResult => {
        const aggByKey = websiteResult.data.aggby_key

        const aggs = websiteResult.data.aggregations[aggByKey].buckets

        let keyed = aggs.map(t => ({
          key: t.key,
          count: t.doc_count
        }))

        if (websiteResult.name === FOURCHAN || websiteResult.name === EIGHTKUN) {
          keyed = keyed.filter(agg => agg.key.toLowerCase() !== 'anonymous')
        }

        return {
          label: websiteResult.label,
          name: websiteResult.name,
          data: keyed
        }
      })
    }
  },
  methods: {
    aggByLocalize (website) {
      return this.$t('setting.aggBy.author')
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.card) {
  margin-top: var(--spacing-4);
}
img {
  width: 25%;
}
</style>
