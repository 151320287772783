<template>
  <div>
    <div class="picker">
      <div>
        {{ locale }}
      </div>
      <RotateArrow :rotate="menu" color="var(--color-black)" />
    </div>
    <q-menu v-model="menu" auto-close fit>
      <q-list>
        <q-item
          v-for="lang in $i18n.availableLocales"
          :key="lang"
          clickable
          @click="locale = lang"
        >
          <q-item-section>
            {{ lang }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </div>
</template>

<script>
import RotateArrow from '@/components/RotateArrow'

export default {
  components: {
    RotateArrow
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    locale: {
      get () {
        return this.$i18n.locale
      },
      set (val) {
        // setting quasar's language as well to get e.g. localized calendars
        // https://quasar.dev/options/quasar-language-packs#Change-Quasar-Language-Pack-at-Runtime
        // switching en with en-us since quasar doesn't have plain en
        const qLang = val === 'en' ? 'en-US' : val
        import(
          `quasar/lang/${qLang}`
        )
          .then((lang) => {
            this.$q.lang.set(lang.default)
          })
          .catch((err) =>
            console.error("Couldn't import quasar language:", err)
          )

        this.$i18n.locale = val
        localStorage.setItem('locale', val)
      }
    },
  },
  created () {
    // try to be smart when picking the default language

    const browserLang = navigator.languages.find((lang) =>
      this.$i18n.availableLocales.includes(lang)
    )

    // default to what the user had last time, if anything
    // otherwise get a language that the browser says the user prefers
    // if we don't have a language like that, fall back to english
    this.locale = localStorage.getItem('locale') || browserLang || 'en'
  }
}
</script>

<style scoped lang="scss">
.picker {
  text-transform: uppercase;
  font-weight: 800;
  font-size: var(--fs-1);
  letter-spacing: 1px;
  border-radius: var(--border-radius-1);
  border: solid 2px var(--color-black);
  cursor: pointer;
  user-select: none;
  color: var(--color-black);
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-0);
  display: flex;
  align-items: center;
}
</style>
