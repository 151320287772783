<template>
  <Line :data="chartData" :options="options"/>
</template>

<script>
import { downloadBase64 } from '@/helpers/graphs'
import 'chart.js/auto'
import { Line } from 'vue-chartjs'

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null
    }
  },
  methods: {
    downloadImage () {
      downloadBase64(this.base64Image)
    }
  },
}
</script>
<style></style>
