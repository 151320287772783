<template>
  <InputContainer :label="label">
    <div class="input-card dropdown-button">
      <div class="current-selected">
        {{ current.label }}
      </div>
      <RotateArrow :rotate="menu" />
    </div>
    <q-menu v-model="menu" auto-close fit>
      <q-list>
        <q-item
          v-for="option in options"
          :key="option.value"
          clickable
          @click="$emit('input', option.value)"
        >
          <q-item-section>
            {{ option.label }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </InputContainer>
</template>

<script>
import InputContainer from './InputContainer'
import RotateArrow from '@/components/RotateArrow'

export default {
  components: {
    InputContainer,
    RotateArrow
  },
  props: {
    label: String,
    value: {
      required: true
    },
    options: Array
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    current () {
      return this.options.find((option) => option.value === this.value)
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-button {
  color: var(--color-black);
  padding: var(--spacing-2);
  cursor: pointer;
  user-select: none;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
}

.current-selected {
  text-align: start;
}
</style>
