<template>
  <div class="toolbar">
    <div>
      <router-view name="settings" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.toolbar {
  background: var(--color-white);
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: center;
  color: var(--color-black);
  max-width: 450px;
  padding-bottom: 4em;
}
</style>
