<template>
  <BaseCard>
    <template #title>
      {{ cardTitle }}
    </template>
    <template #content>
      <BaseTable :titles="titles" :tableData="tableData" />
    </template>
    <template #footer>
      <ButtonDownloadCSV :titles="titles" :tableData="tableData" />
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/BaseCard'
import BaseTable from './BaseTable'
import ButtonDownloadCSV from '@/components/input/ButtonDownloadCSV'

export default {
  components: {
    BaseCard,
    BaseTable,
    ButtonDownloadCSV
  },
  props: {
    cardTitle: String,
    titles: Array,
    resultsData: Array
  },
  computed: {
    tableData () {
      return this.resultsData.map((h) => [h.key, h.count])
    }
  }
}
</script>

<style scoped lang="scss"></style>
