<template>
  <table>
    <thead>
      <tr>
        <td v-for="title in titles" :key="title">{{ title }}</td>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(row, i) in tableData" :key="`${i}:${row[i]}`">
        <td v-for="(col, i) in row" :key="i">{{ col }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    titles: Array,
    tableData: Array
  }
}
</script>

<style scoped lang="scss">
table {
  width: 100%;
  font-family: var(--primary-font);
  // border-spacing: 0;
}

thead {
  text-transform: uppercase;
  font-weight: 800;
  font-family: var(--primary-font);
}

td {
  padding: var(--spacing-1) var(--spacing-0);
  font-family: var(--primary-font);
}

tr td:nth-of-type(2) {
  text-align: end;
  font-family: var(--primary-font);
}

tbody {
  font-size: var(--fs-2);
  font-family: var(--primary-font);
  font-weight: 300;
}

tbody tr:nth-child(odd) {
  background: var(--color-light-bg);
  font-family: var(--primary-font);
}
</style>
