<template>
  <div class="input-card">
    <input
      class="input"
      type="text"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value), $emit('update:value', $event.target.value)"
      :minlength="minlength"
      :pattern="pattern"
      required
    />
    <div class="slot">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: String,
    minlength: String,
    pattern: String
  },
  emits: ['input', 'update:value']
}
</script>

<style scoped lang="scss">
.input {
  background: var(--color-white);
}

.input {
  flex: auto;
  border: none;
  padding: var(--spacing-2);
  width: 5rem;
}

.slot {
  color: initial;
}

::placeholder {
  color: var(--color-light-text);
}
</style>
