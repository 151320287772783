<template>
  <SearchHelper page="links" v-slot="slotProps">
    <LinksBody v-bind="slotProps" />
  </SearchHelper>
</template>

<script>
import SearchHelper from '@/components/SearchHelper'
import LinksBody from '@/components/bodies/LinksBody'

export default {
  components: {
    SearchHelper,
    LinksBody
  }
}
</script>

<style scoped lang="scss"></style>
