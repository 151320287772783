<template>
  <InputContainer :label="label">
    <BaseTextInput
      placeholder="YYYY-MM-DD"
      pattern="\d{4}-\d{2}-\d{2}"
      :value="value"
      @input="$emit('input', $event)"
    >
      <template #append>
        <BaseIcon name="event" class="cal-icon" />
        <q-popup-proxy
          ref="dateMenu"
          transition-show="scale"
          transition-hide="scale"
          anchor="center right"
          self="center left"
        >
          <q-date
            today-btn
            no-unset
            color="our-purple"
            mask="YYYY-MM-DD"
            :model-value="value"
            @update:model-value="updateValue"
            :options="dateOptions"
          >
            <span class="free-text">{{ $t("setting.freeDates") }}</span>
          </q-date>
        </q-popup-proxy>
      </template>
    </BaseTextInput>
    <BaseTooltip v-if="tooLateError" always-show is-error>
      {{ $t("setting.freeDates") }}
    </BaseTooltip>
  </InputContainer>
</template>

<script>
import InputContainer from './InputContainer'
import BaseTextInput from './BaseTextInput'
import BaseIcon from '@/components/BaseIcon'
import BaseTooltip from '@/components/BaseTooltip'
import { dateIsOldEnough } from '@/helpers/validate'

export default {
  components: {
    InputContainer,
    BaseTextInput,
    BaseIcon,
    BaseTooltip,
  },
  props: {
    label: String,
    placeholder: String,
    value: String
  },
  computed: {
    tooLateError () {
      return !this.dateOptions(this.value)
    },
  },
  methods: {
    updateValue (newValue) {
      this.$emit('input', newValue)
      this.$refs.dateMenu.hide()
    },
    dateOptions (date) {
      return dateIsOldEnough(date)
    },
  },
  emits: ['input']
}
</script>

<style lang="scss">
.free-text {
  font-family: var(--primary-font);
}
</style>

<style scoped lang="scss">
.cal-icon {
  cursor: pointer;
}
</style>
