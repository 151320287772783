<template>
  <SearchHelper page="search" v-slot="slotProps">
    <SearchBody v-bind="slotProps" />
  </SearchHelper>
</template>

<script>
import SearchHelper from '@/components/SearchHelper'
import SearchBody from '@/components/bodies/SearchBody'

export default {
  components: {
    SearchHelper,
    SearchBody
  }
}
</script>

<style scoped lang="scss"></style>
