<template>
  <BaseCard>
    <template #content>
      <ChartTimeline ref="chart" :resultsData="website" :searchSettings="searchSettings" :website="website.label" :timelineType="$t('chart.timelineOn')" :subTitle="$t('chart.postsPer')"/>
    </template>
    <template #footer>
      <FatButton @click="$refs.chart.downloadImage()">
        {{ $t("chart.downloadPNG") }}
      </FatButton>
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/BaseCard'
import ChartTimeline from '@/components/charts/ChartTimeline'
import FatButton from '@/components/input/FatButton'

export default {
  components: {
    BaseCard,
    ChartTimeline,
    FatButton
  },
  props: {
    lastSearchSettings: Object,
    website: Object
  },
  computed: {
    searchSettings () {
      return this.lastSearchSettings
    },
  }
}
</script>

<style scoped lang="scss">
img {
  width: 25%;
}
</style>
