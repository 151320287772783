import om from '@/assets/Open-Measures-Stacked-Black.svg'

export function downloadBase64 (base64) {
  const a = document.createElement('a')
  a.href = base64
  a.download = 'open-measures-chart.png'
  a.click()
}

export function color (name) {
  const style = getComputedStyle(document.body)
  return style.getPropertyValue(name)
}

export const chartPlugins = ({ logoAtBottomRight } = {}) => {
  const image = new Image()
  image.src = om

  return [
    {
      id: 'plainWhiteBackground',
      beforeDraw: (chart) => {
        const ctx = chart.ctx
        ctx.save()
        ctx.globalCompositeOperation = 'destination-over'
        ctx.fillStyle = color('--color-white')
        ctx.fillRect(0, 0, chart.width, chart.height)
        ctx.restore()
      }
    },
    {
      id: 'logoInBackground',
      beforeDatasetsDraw: (chart) => {
        if (image.complete) {
          const ctx = chart.ctx
          const { top, bottom, left, right, height, width } = chart.chartArea
          const imgWidthRatio = logoAtBottomRight ? 3 : 2
          // numbers are the size the svg renders in an image viewer
          // NOTE: remember to update this if you change the svg
          const imgAspectRatio = 1437 / 329
          const imgWidth = width / imgWidthRatio
          const imgHeight = imgWidth / imgAspectRatio
          const x = logoAtBottomRight
            ? right - imgWidth - 10
            : left + (width / 2) - (imgWidth / 2)
          const y = logoAtBottomRight
            ? bottom - imgHeight - 10
            : top + (height / 2) - (imgHeight / 2)
          ctx.globalAlpha = 0.15
          ctx.drawImage(image, x, y, imgWidth, imgHeight)
          ctx.globalAlpha = 1
        } else {
          image.onload = () => chart.draw()
        }
      }
    }
  ]
}