<template>
  <button @click="$emit('click')" v-ripple>
    <div class="inner">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
button {
  background: none;
  border: none;
  color: var(--color-black);
  font-size: var(--fs-2);
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  cursor: pointer;
}


.inner {
  --bottom-margin: 4px;

  border-bottom: solid var(--bottom-margin) transparent;
  padding: calc(var(--bottom-margin) + 5px) var(--spacing-1) 5px;
  letter-spacing: 1px;
  width: 100%;
}

.inner:hover {
  border-bottom: solid var(--bottom-margin) var(--color-purple);
}
</style>
