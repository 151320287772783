<template>
  <InputDropdown
    :label="$t('setting.interval')"
    :value="interval"
    @input="setInterval"
    :options="options"
  />
</template>

<script>
import InputDropdown from './InputDropdown'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputDropdown
  },
  computed: {
    ...mapGetters(['interval']),
    options () {
      return ['hour', 'day', 'week', 'month'].map((time) => ({
        label: this.$t(`setting.timely.${time}`),
        value: time
      }))
    }
  },
  methods: {
    ...mapMutations(['setInterval'])
  }
}
</script>

<style scoped lang="scss"></style>
