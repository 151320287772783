<template>
  <div id="app">
    <q-layout>
      <q-header>
        <TheTopBar
          @toggleToolBar="showSettingsBar = !showSettingsBar"
        />
      </q-header>

      <template v-if="onTool">
        <q-drawer
          v-if="large"
          v-model="drawer"
          show-if-above

          :mini="!drawer || miniState"

          :width="366"
          :breakpoint="500"
        >
          <div
            class="sidebar"
          >
            <div>
              <div class='sidebar-title'>{{ $t("sidebar.title") }}</div>
              <div>
                <router-view name="settings" />
              </div>
            </div>
          </div>

          <template v-slot:mini>
            <q-scroll-area class="fit mini-slot cursor-pointer">
              <div class="column items-center" style='padding-top: 6px;' @click="miniState = false">
                <IconTool tool='openSidebar' :size='50' noBackground />
              </div>
            </q-scroll-area>
          </template>


          <div class="q-mini-drawer-hide absolute close-sidebar" @click="miniState = true">
            <IconTool tool='closeSidebar' :size='40' noBackground />
          </div>

        </q-drawer>
        <BaseModal v-else v-model:value="showSettingsBar">
          <TheSettingsBar />
        </BaseModal>
      </template>

      <q-page-container>
        <ThePage />
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import TheTopBar from '@/components/TheTopBar'
import TheSettingsBar from '@/components/TheSettingsBar'
import IconTool from '@/components/input/tool-picker/IconTool'
import BaseModal from '@/components/BaseModal'
import ThePage from '@/components/ThePage'
import { createNamespacedHelpers, useStore } from 'vuex'
import { useFavicon, useTitle } from '@vueuse/core'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'


const { TIMELINE, LINKS, ACTIVITY, SEARCH } = require('@/constants/tools')

const { mapActions: mapSettingsActions } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    TheTopBar,
    TheSettingsBar,
    ThePage,
    BaseModal,
    IconTool
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const { t } = useI18n()

    const showSettingsBar = ref(true)
    const large = ref(false)

    store.subscribeAction((action) => {
      if (action.type === 'searchSettings/clickSearch' && !large.value) {
        // TODO: removing this seems to have no effect, it still works?
        showSettingsBar.value = false
      }
    })

    const onTool = computed(() => [TIMELINE, LINKS, ACTIVITY, SEARCH].includes(route.name))

    useFavicon('favicon-32x32.png')

    const title = computed(() => {
      if (onTool.value) {
        return `${t(`nav.${route.name}`)} | Open Measures`
      } else {
        return 'Open Measures'
      }
    })
    useTitle(title)

    return { showSettingsBar, large, onTool }
  },
  data () {
    return {
      drawer: true,
      miniState: false
    }
  },
  methods: {
    ...mapSettingsActions(['onCreated']),
    updateLarge () {
      this.large = window.matchMedia('(min-width: 1000px)').matches
    },
  },
  created () {
    this.updateLarge()
    window.addEventListener('resize', this.updateLarge)

    this.onCreated(this.$store)
  }
}
</script>

<style lang="scss">
@import "global";
</style>

<style scoped lang="scss">
a {
  text-decoration: none !important;
}

#app {
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
  background: var(--color-dark);
}

.sidebar {
  min-height: 100%;
  background: var(--color-off-white);
  width: 100%;
  padding: var(--spacing-3) var(--spacing-4);
  border-right: var(--border-width) solid var(--color-light-border);
  text-align: left;
  color: var(--color-black);
}

.sidebar-title {
  background: var(--color-off-white);
  color: var(--color-black);
  font-size: 20px;
  font-weight: bold;
}

.mini-slot {
  background: var(--color-off-white);
}

.close-sidebar {
  top: 10px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}


</style>
