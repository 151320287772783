<template>
  <InputContainer :label="label">
    <q-slider
      color="black"
      :min="2"
      :max="50"
      :model-value="numberOf"
      @update:model-value="setNumberOf"
    />
    <div class="numbers">
      <span class="min">{{ min }}</span>
      <span class="current">{{ numberOf }}</span>
      <span class="max">{{ max }}</span>
    </div>
  </InputContainer>
</template>

<script>
import InputContainer from './InputContainer'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputContainer
  },
  props: {
    label: String
  },
  data () {
    return {
      min: 2,
      max: 50
    }
  },
  computed: {
    ...mapGetters(['numberOf'])
  },
  methods: {
    ...mapMutations(['setNumberOf'])
  }
}
</script>

<style scoped lang="scss">
.numbers {
  color: var(--color-black);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current {
  font-size: var(--fs-5);
  font-weight: bold;
}
</style>
