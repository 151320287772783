<template>
  <BaseIcon v-if='name' :name='name' :class='website' size='1.2em' />
  <img v-else :src='src' :class='website' />
</template>

<script>
import BaseIcon from './BaseIcon'
import gab from '@/assets/gab.svg'
import fourchan from '@/assets/4chan.svg'
import eightkun from '@/assets/8kun.svg'
import parler from '@/assets/parler.svg'
import poal from '@/assets/poal.svg'
import thedonald from '@/assets/thedonald.svg'
import telegram from '@/assets/telegram.svg'
import gettr from '@/assets/gettr.svg'
import bitchute from '@/assets/bitchute.svg'
import mewe from '@/assets/mewe.svg'
import wimkin from '@/assets/wimkin.svg'
import rumble from '@/assets/rumble.svg'
import minds from '@/assets/minds.svg'
import lbry from '@/assets/lbry.svg'
import vk from '@/assets/vk.svg'
import truthsocial from '@/assets/truthsocial.svg'
import tiktok from '@/assets/tiktok.svg'
import rutube from '@/assets/rutube.svg'
import ok from '@/assets/ok.svg'
import fediverse from '@/assets/fediverse.svg'
import bluesky from '@/assets/bluesky.svg'
const { TWITTER, GAB, FOURCHAN, EIGHTKUN, PARLER, POAL, WIN, TELEGRAM, GETTR, BITCHUTE_VIDEO, BITCHUTE_COMMENT, MEWE, WIMKIN, RUMBLE_COMMENT, RUMBLE_VIDEO, MINDS, LBRY_VIDEO, LBRY_COMMENT, VK, TRUTH_SOCIAL, TIKTOK_VIDEO, TIKTOK_COMMENT, RUTUBE_COMMENT, RUTUBE_VIDEO, OK, FEDIVERSE, BLUESKY } = require('@/constants/sites')

export default {
  components: {
    BaseIcon
  },
  props: {
    website: String
  },
  computed: {
    name () {
      switch (this.website) {
        case TWITTER: return 'fab fa-twitter'
        default: return false
      }
    },
    src () {
      switch (this.website) {
        case GAB: return gab
        case FOURCHAN: return fourchan
        case EIGHTKUN: return eightkun
        case PARLER: return parler
        case POAL: return poal
        case WIN: return thedonald
        case TELEGRAM: return telegram
        case GETTR: return gettr
        case BITCHUTE_VIDEO: return bitchute
        case BITCHUTE_COMMENT: return bitchute
        case MEWE: return mewe
        case WIMKIN: return wimkin
        case RUMBLE_COMMENT: return rumble
        case RUMBLE_VIDEO: return rumble
        case LBRY_COMMENT: return lbry
        case LBRY_VIDEO: return lbry
        case MINDS: return minds
        case VK: return vk
        case TRUTH_SOCIAL: return truthsocial
        case TIKTOK_VIDEO: return tiktok
        case TIKTOK_COMMENT: return tiktok
        case RUTUBE_VIDEO: return rutube
        case RUTUBE_COMMENT: return rutube
        case OK: return ok
        case BLUESKY: return bluesky
        case FEDIVERSE: return fediverse
        default: return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.twitter {
  color: #65bbf2;
}

img {
  width: 1.2rem;
}
</style>
