import moment from 'moment'
import { PUBLIC_APP } from '@/constants/misc'

export function validInput (settings) {
  return dateIsOldEnough(settings.startDate) &&
    dateIsOldEnough(settings.endDate)
}

export function dateIsOldEnough (date) {
  if (!PUBLIC_APP) return true

  // if we're on the public version then we only allow picking dates older than half a year
  const halfYearBack = moment().subtract(0.5, 'year').subtract(1, 'day')

  return moment(date).isBefore(halfYearBack)
}