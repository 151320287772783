<template>
  <div>
    <form @submit.prevent="clickSearch('timeline')">
      <ButtonOpenToolModal />
      <InputSearchTerm />
      <InputDates />
      <InputWebsites />
      <InputInterval />
      <InputChangepoint />
      <FatButton width='100%'>
        {{ $t("setting.button.timeline") }}
      </FatButton>
    </form>
  </div>
</template>

<script>
import ButtonOpenToolModal from '@/components/input/tool-picker/ButtonOpenToolModal'
import InputSearchTerm from '@/components/input/InputSearchTerm'
import InputDates from '@/components/input/InputDates'
import InputWebsites from '@/components/input/InputWebsites'
import InputInterval from '@/components/input/InputInterval'
import InputChangepoint from '@/components/input/InputChangepoint'
import FatButton from '@/components/input/FatButton'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    ButtonOpenToolModal,
    InputSearchTerm,
    InputDates,
    InputWebsites,
    InputInterval,
    InputChangepoint,
    FatButton,
  },
  methods: {
    ...mapActions(['clickSearch'])
  }
}
</script>

<style scoped lang="scss">
form > * {
  margin-top: var(--spacing-5);
}
</style>
