import general from './modules/general'
import searchSettings from './modules/searchSettings'

export default {
  modules: {
    general,
    searchSettings
  },
  strict: process.env.NODE_ENV === 'development'
}
