<template>
  <SearchHelper page="activity" v-slot="slotProps">
    <ActivityBody v-bind="slotProps" />
  </SearchHelper>
</template>

<script>
import SearchHelper from '@/components/SearchHelper'
import ActivityBody from '@/components/bodies/ActivityBody'

export default {
  components: {
    SearchHelper,
    ActivityBody
  }
}
</script>

<style scoped lang="scss"></style>
