<template>
  <div class="same-row">
    <InputStartDate />
    <InputEndDate />
  </div>
</template>

<script>
import InputStartDate from '@/components/input/InputStartDate'
import InputEndDate from '@/components/input/InputEndDate'

export default {
  components: {
    InputStartDate,
    InputEndDate
  },
  props: {
    type: String
  }
}
</script>

<style scoped lang="scss">
.same-row {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-3);
}
</style>
