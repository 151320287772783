<template>
  <div>
    <div v-if="resultsAvailable">
      <div>
        <BaseCard v-for="(website, i) in resultsData" :key="website.label">
          <template #content>
            <BaseBarChart ref="chart" :resultsData="website.data" :searchSettings="searchSettings" :website="website.label" :barType="$t('chart.linkCountOn')" :subTitle="$t('chart.popularLinksWith')"/>
          </template>
          <template #footer>
            <FatButton @click="$refs.chart[i].downloadImage()">
              {{ $t("chart.downloadPNG") }}
            </FatButton>
          </template>
        </BaseCard>
      </div>

      <div>
        <TableCount
          v-for="website in resultsData"
          :key="website.label"
          :card-title="$t('table.viewFor') + website.label"
          :titles="[$t('table.urls'), $t('table.urlCount')]"
          :resultsData="website.data"
        />
      </div>
    </div>
    <BodyLoading :loading="loading" />
    <BodyError :error="error" />
  </div>
</template>

<script>
import { isEmpty, flatten, uniq } from 'lodash'

import BaseCard from '@/components/BaseCard'
import BaseBarChart from '@/components/charts/BaseBarChart'
import FatButton from '@/components/input/FatButton'
import TableCount from '@/components/tables/TableCount'
import BodyLoading from './BodyLoading'
import BodyError from './BodyError'
const { TWITTER, GAB, FOURCHAN, EIGHTKUN, PARLER, POAL, WIN, TELEGRAM, GETTR, BITCHUTE_VIDEO, BITCHUTE_COMMENT, MEWE, WIMKIN, RUMBLE_COMMENT, RUMBLE_VIDEO, MINDS, LBRY_VIDEO, LBRY_COMMENT, VK, TRUTH_SOCIAL, TIKTOK_VIDEO, TIKTOK_COMMENT, RUTUBE_VIDEO, RUTUBE_COMMENT, BLUESKY, FEDIVERSE } = require('@/constants/sites')

export default {
  components: {
    BaseCard,
    BaseBarChart,
    FatButton,
    TableCount,
    BodyLoading,
    BodyError
  },

  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  computed: {
    searchSettings () {
      return this.lastSearchSettings
    },
    /**
     * Takes the raw result from the fetch and turns it into a nice format
     * that we can use
     */
    resultsData () {
      // results[0].data.hits.hits[0]["_source"]["entities"]["urls"] is array
      if (this.results === null) return []

      return this.results.map((website) => {
        const hits = website.data.hits.hits // this be a list
        let allLinks = []

        switch (website.name) {
          case MINDS:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.body)
                .map(this.getAllLinks)
            )
            break

          case LBRY_VIDEO:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.value.description)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case LBRY_COMMENT:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.comment)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case RUMBLE_VIDEO:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.full_description)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case RUMBLE_COMMENT:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.text)
                .map(this.getAllLinks)
            )
            break

          case BITCHUTE_VIDEO:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.meta.description)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case BITCHUTE_COMMENT:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.content)
                .filter(links => !isEmpty(links))
            ).map(link => link.expanded_url)
            break

          case TIKTOK_VIDEO:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.desc)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case TIKTOK_COMMENT:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.text)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case RUTUBE_VIDEO:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.description)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case RUTUBE_COMMENT:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.text)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case TWITTER:
            allLinks = flatten(
              hits
                .map(hit => hit._source.entities.urls)
                .filter(links => !isEmpty(links))
            ).map(link => link.expanded_url)
            break

          case GETTR:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.txt)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case POAL:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.content)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case WIN:
            // body is markdown
            allLinks = flatten(
              hits
                .map((hit) => hit._source.html_parsed_html)
                .map(this.getAllLinks)
            )
            break

          case TELEGRAM:
            // body is markdown
            allLinks = flatten(
              hits
                .map((hit) => hit._source.message)
                .filter(links => !isEmpty(links))
                .map(this.getAllLinks)
            )
            break

          case PARLER:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.bodywithurls)
                .map(this.getAllLinks)
            )
            break

          case MEWE:
            // _source.content is raw html. if using it be careful not to
            // doublecount links (since raw links also turn into <a> links)
            // _source.body is the actual post text that the user posts, i think
            allLinks = flatten(
              hits
                .map((hit) => hit._source.content)
                .map(this.getAllLinks)
            )
            break

          case WIMKIN:
            // _source.content is raw html. if using it be careful not to
            // doublecount links (since raw links also turn into <a> links)
            // _source.body is the actual post text that the user posts, i think
            allLinks = flatten(
              hits
                .map((hit) => hit._source.content)
                .map(this.getAllLinks)
            )
            break

          case VK:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.text)
                .map(this.getAllLinks)
            )
            break

          case TRUTH_SOCIAL:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.content_cleaned)
                .map(this.getAllLinks)
            )
            break

          case GAB:
            // _source.content is raw html. if using it be careful not to
            // doublecount links (since raw links also turn into <a> links)
            // _source.body is the actual post text that the user posts, i think
            allLinks = flatten(
              hits
                .map((hit) => hit._source.content)
                .map(this.getAllLinks)
            )
            break

          case BLUESKY:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.text)
                .map(this.getAllLinks)
            )
            break

          case FEDIVERSE:
            allLinks = flatten(
              hits
                .map((hit) => hit._source.content_cleaned)
                .map(this.getAllLinks)
            )
            break

          case FOURCHAN:
          case EIGHTKUN:
            // com is html
            // TODO might need to check for plain text links vs <a> links (see GAB note)
            allLinks = flatten(
              hits
                .map(hit => hit._source.com)
                .map(this.getAllLinks)
            )
            break

          default:
            console.error(`no result processor for ${website.name} links`)
        }
        // TODO move this into vuex actions
        // can write tests over example data, and split out the fetching + processing of different sources, then show page as reuslts arrive!

        const uniqueLinks = uniq(allLinks)
        const links = uniqueLinks.map((link) => {
          const count = allLinks.filter((tag) => tag === link).length
          return {
            key: link,
            count: count
          }
        })

        links.sort((a, b) => b.count - a.count)

        return {
          label: website.label,
          data: links.slice(0, this.lastSearchSettings.numberOf)
        }
      })
    }
  },
  methods: {
    getAllLinks (text) {
      let regex
      if (this.lastSearchSettings.hostRegex) {
        regex = /https?:\/\/(?:[-\w.]|(?:%[\da-fA-F]{2}))+/g
      } else {
        regex = /https?:\/\/(?:[-\w.]|(?:%[\da-fA-F]{2}))+[^ ]*/g
      }
      const match = text.match(regex)
      return match || []
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  margin-top: var(--spacing-4);
  margin-bottom: 50px;
}
img {
  width: 25%;
}
</style>
