<template>
  <div v-if='error != null'>
    <div class='human'>Search failed</div>

    <div class='error'>
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['error'],
}
</script>

<style scoped lang="scss">
.human {
  font-size: 18px;

  margin-bottom: 2rem;
}

.error {
  font-family: monospace;
  background: #fff;
  padding: 1rem;
}
</style>
